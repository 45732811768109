<template>
  <div id="reset-password">
    <section class="bg-light ftco-section">
      <div class="d-flex justify-content-center">
        <img
          src="@/assets/site/images/loader.gif"
          v-if="loader === true"
          class="img-fluid  loader-width"
          alt
        />
      </div>
      <div class="container" v-if="loader === false">
        <div class="row flex-lg-row flex-md-row">
          <div class="col-md-5">
            <div style="margin-top: 2rem">
              <div class="card-body bg-dark-gray">
                <h2 class="mb-3 text-black">Reset Password</h2>
                <form>
                  <input
                    type="hidden"
                    class="form-control"
                    v-model="api_token"
                  />
                  <b-alert
                    show
                    :variant="color"
                    v-if="response_message != null"
                  >
                    {{ response_message }}
                  </b-alert>
                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control"
                      v-model="email"
                      placeholder="Enter Your Email Address"
                    />
                    <span class="text-danger" v-if="errors.email">{{
                      this.errors.email[0]
                    }}</span>
                  </div>
                  <div class="form-group">
                    <input
                      type="password"
                      class="form-control"
                      v-model="password"
                      placeholder="Password*"
                    />
                    <span class="text-danger" v-if="errors.password">{{
                      this.errors.password[0]
                    }}</span>
                  </div>
                  <div class="form-group">
                    <input
                      type="password"
                      class="form-control"
                      v-model="confirm_password"
                      placeholder="Confirm Password*"
                    />
                    <span class="text-danger" v-if="errors.confirm_password">{{
                      this.errors.confirm_password[0]
                    }}</span>
                  </div>
                  <div class="row mb-1">
                    <!-- /.col -->
                    <div class="col-6 mt-4 mb-2">
                      <button
                        @click="updatePassword"
                        type="button"
                        class="btn btn-primary btn-block px-4"
                      >
                        Change Password
                      </button>
                    </div>
                  </div>
                  <p style="font-size:14px">
                    Don’t have an account?
                    <router-link to="/site/signup"> Sign Up</router-link> |
                    <router-link to="/site" exact style="font-size:14px"
                      >Login</router-link
                    >
                  </p>

                  <!-- /.col -->
                </form>
              </div>
              <!-- /.login-card-body -->
            </div>
          </div>
          <div class="col-md-7">
            <img
              src="@/assets/site/images/login-bg.jpg"
              alt=""
              class="img-fluid"
            />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { parseJwt } from "@/utils/parseJwt";

export default {
  name: "ResetPassword",
  metaInfo: {
    title: "Reset Password",
    meta: [
      {
        name: "description",
        content:
          "Easily reset your password with our intuitive interface. Enter your email, set a new password, and confirm it in just a few clicks. Secure your account today!",
      },
      {
        name: "keywords",
        content:
          "reset password, password recovery, account security, Vue.js reset password template, email input, password validation, confirm password, user-friendly interface, loader animation, response messages, sign up link, login link, form validation, mobile responsive design, account management.",
      },
    ],
  },
  data() {
    return {
      email: null,
      errors: [],
      response_message: null,
      loader: false,
      color: "success",
      confirm_password: null,
      password: null,
      api_token: null,
    };
  },
  created() {
    const token = this.$route.params.token;
    const parsedToken = parseJwt(token);

    if (!parsedToken) {
      this.$swal("Error", "Invalid token!", "error");
      this.$router.push("/home");
    } else {
      const expiry = new Date(parsedToken.exp * 1000);

      if (expiry.getTime() < Date.now()) {
        this.$swal("Error", "Token expired!", "error");
        this.$router.push("/home");
      } else this.getUrl();
    }
  },
  methods: {
    getUrl() {
      this.loader = true;
      let apiUrl = process.env.VUE_APP_URL + "customer/password/getMail";
      let method_type = "POST";
      fetch(apiUrl, {
        method: method_type,
        headers: {
          "content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
        body: JSON.stringify({
          token: this.$route.params.token,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          this.loader = false;
          this.api_token = this.$route.params.token;

          this.email = data.email;
        })
        .catch(function(err) {
          console.log("User Data", "Error : " + err.message, "error");
        });
    },
    updatePassword() {
      this.errors = [];
      this.response_message = null;
      this.loader = true;
      let apiUrl = process.env.VUE_APP_URL + "customer/password/reset";
      let method_type = "POST";
      fetch(apiUrl, {
        method: method_type,
        headers: {
          "content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
        body: JSON.stringify({
          email: this.email,
          token: this.api_token,
          password: this.password,
          confirm_password: this.confirm_password,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          this.loader = false;
          if (data.status === true) {
            this.color = "success";
            this.response_message = "Password changed successfully!";
            this.email = null;
          } else if (data.status === false) {
            this.color = "danger";
            this.response_message = data.message;
          } else {
            this.errors = data.errors;
          }
        })
        .catch(function(err) {
          console.log("User Data", "Error : " + err.message, "error");
        });
    },
  },
};
</script>
